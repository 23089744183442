// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-administrador-js": () => import("./../../../src/pages/administrador.js" /* webpackChunkName: "component---src-pages-administrador-js" */),
  "component---src-pages-credencial-usuario-js": () => import("./../../../src/pages/credencialUsuario.js" /* webpackChunkName: "component---src-pages-credencial-usuario-js" */),
  "component---src-pages-estudiantes-js": () => import("./../../../src/pages/estudiantes.js" /* webpackChunkName: "component---src-pages-estudiantes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-libro-digital-js": () => import("./../../../src/pages/libroDigital.js" /* webpackChunkName: "component---src-pages-libro-digital-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */)
}

